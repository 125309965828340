
import './App.css';
import ValueAddTestR from './Components/ValueAddTestR';


function App() {
  return (
    <div className="App">
    <ValueAddTestR/>
    </div>
  );
}

export default App;
