import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Timer, Home, ArrowRight, AlertCircle, CheckCircle } from 'lucide-react';

const ValueAddTestR = () => {
  // State declarations with default values
  const [sessionStartTime] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [confirmedSteps, setConfirmedSteps] = useState([]);
  const [numStories, setNumStories] = useState('');
  const [userStories, setUserStories] = useState([]);
  const [testSteps, setTestSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startTime] = useState(new Date());
  const [savedTime, setSavedTime] = useState(0);
  const [testResults, setTestResults] = useState({
    total: 0,
    passed: 0,
    failed: 0
  });

  const handleNumStoriesChange = (value) => {
    const num = parseInt(value);
    setNumStories(value);
    if (num > 0 && num <= 100) {
      setUserStories(Array(num).fill(''));
    } else {
      setUserStories([]);
    }
  };

  const handleStoryChange = (index, value) => {
    if (value.length <= 1000) {
      const newStories = [...userStories];
      newStories[index] = value;
      setUserStories(newStories);
    }
  };

  const generateTestSteps = () => {
    const steps = userStories.flatMap((story, index) => {
      if (!story.trim()) return [];
      
      const lines = story.split('\n').map(line => line.trim());
      const storySteps = [];
      
      let currentStep = '';
      lines.forEach(line => {
        if (line.toLowerCase().startsWith('scenario:')) {
          const scenario = line.substring(9).trim();
          storySteps.push({
            id: `${index}-${storySteps.length}`,
            description: `Verify scenario: ${scenario}`,
            expected: `Scenario "${scenario}" is valid and ready for testing`,
            actual: '',
            status: 'N/A'
          });
        }
        else if (line.toLowerCase().startsWith('given ')) {
          currentStep = line.substring(6).trim();
          storySteps.push({
            id: `${index}-${storySteps.length}`,
            description: `Verify precondition: ${currentStep}`,
            expected: `Initial condition "${currentStep}" is met`,
            actual: '',
            status: 'N/A'
          });
        }
        else if (line.toLowerCase().startsWith('when ')) {
          currentStep = line.substring(5).trim();
          storySteps.push({
            id: `${index}-${storySteps.length}`,
            description: `Execute action: ${currentStep}`,
            expected: `Action "${currentStep}" is performed successfully`,
            actual: '',
            status: 'N/A'
          });
        }
        else if (line.toLowerCase().startsWith('and ')) {
          currentStep = line.substring(4).trim();
          storySteps.push({
            id: `${index}-${storySteps.length}`,
            description: `Execute additional action: ${currentStep}`,
            expected: `Additional action "${currentStep}" is performed successfully`,
            actual: '',
            status: 'N/A'
          });
        }
        else if (line.toLowerCase().startsWith('then ')) {
          currentStep = line.substring(5).trim();
          storySteps.push({
            id: `${index}-${storySteps.length}`,
            description: `Verify outcome: ${currentStep}`,
            expected: `Expected outcome "${currentStep}" is achieved`,
            actual: '',
            status: 'N/A'
          });
        }
      });

      if (storySteps.length === 0 && story.trim()) {
        storySteps.push({
          id: `${index}-0`,
          description: `Verify user story implementation`,
          expected: `Story requirements are met`,
          actual: '',
          status: 'N/A'
        });
      }

      return storySteps;
    });
    setTestSteps(steps);
  };

  const startLoading = (nextPage) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setCurrentPage(nextPage);
    }, 2000);
  };

  const updateTestResults = () => {
    const results = testSteps.reduce((acc, step) => {
      acc.total++;
      if (step.status === 'pass') acc.passed++;
      if (step.status === 'fail') acc.failed++;
      return acc;
    }, { total: 0, passed: 0, failed: 0 });
    setTestResults(results);
  };

  const calculateSavedTime = () => {
    const currentTime = new Date();
    const timeSpent = Math.floor((currentTime - startTime) / 1000);
    setSavedTime(timeSpent + 120);
  };

  useEffect(() => {
    if (currentPage === 4) {
      updateTestResults();
      calculateSavedTime();
    }
  }, [currentPage]);

  const renderPage1 = () => (
    <div className="max-w-4xl mx-auto p-4 md:p-8">
      <h1 className="text-2xl md:text-4xl font-bold text-gray-800 mb-8 text-center">
        ValueAddTestR (NV)
      </h1>
      <div className="space-y-6">
        <div className="bg-white rounded-lg shadow-xl p-6">
          <label className="block text-lg md:text-xl font-medium text-gray-700 mb-4">
            Enter the number of user stories:
          </label>
          <input
            type="number"
            min="1"
            max="100"
            value={numStories}
            onChange={(e) => handleNumStoriesChange(e.target.value)}
            className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        {userStories.length > 0 && (
          <>
            <div className="space-y-6">
              {userStories.map((story, index) => (
                <div key={index} className="bg-white rounded-lg shadow-xl p-6">
                  <label className="block text-lg font-medium text-gray-700 mb-2">
                    User Story #{index + 1}
                  </label>
                  <textarea
                    value={story}
                    onChange={(e) => handleStoryChange(index, e.target.value)}
                    className="w-full h-32 px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter your user story in Gherkin format (max 1000 characters)
Example:
Scenario: Login
Given I have the URL
When I key in credentials
And hit submit button
Then I login successfully into application"
                    maxLength={1000}
                  />
                  <div className="text-sm text-gray-500 mt-2">
                    {1000 - story.length} characters remaining
                  </div>
                </div>
              ))}
              <button
                onClick={() => {
                  generateTestSteps();
                  startLoading(3);
                }}
                className="w-full md:w-auto px-8 py-3 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-lg shadow-lg hover:from-green-700 hover:to-blue-700 transition duration-300 ease-in-out transform hover:-translate-y-1 flex items-center justify-center gap-2"
              >
                <ArrowRight className="w-5 h-5" />
                Generate Test Steps
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
  const renderPage2 = () => (
    <div className="max-w-4xl mx-auto p-4 md:p-8">
      <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-6">
        Enter User Stories
      </h2>
      <div className="space-y-6">
        {userStories.map((story, index) => (
          <div key={index} className="bg-white rounded-lg shadow-xl p-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              User Story #{index + 1}
            </label>
            <textarea
              value={story}
              onChange={(e) => handleStoryChange(index, e.target.value)}
              className="w-full h-32 px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your user story (max 1000 characters)"
              maxLength={1000}
            />
            <div className="text-sm text-gray-500 mt-2">
              {1000 - story.length} characters remaining
            </div>
          </div>
        ))}
        <button
          onClick={() => {
            generateTestSteps();
            startLoading(3);
          }}
          className="w-full md:w-auto px-8 py-3 bg-gradient-to-r from-green-600 to-blue-600 text-white rounded-lg shadow-lg hover:from-green-700 hover:to-blue-700 transition duration-300"
        >
          Generate Test Steps
        </button>
      </div>
    </div>
  );
  const renderPage3 = () => (
    <div className="max-w-6xl mx-auto p-4 md:p-8">
      <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-6">
        Test Steps Input
      </h2>
      <div className="overflow-x-auto">
        <table className="w-full bg-white rounded-lg shadow-xl">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-3 text-left">Step Description</th>
              <th className="px-4 py-3 text-left">Expected Result</th>
              <th className="px-4 py-3 text-left">Actual Result</th>
              <th className="px-4 py-3 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {testSteps.map((step) => (
              <tr key={step.id} className="border-t">
                <td className="px-4 py-3">{step.description}</td>
                <td className="px-4 py-3">{step.expected}</td>
                <td className="px-4 py-3">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border rounded"
                    value={step.actual}
                    onChange={(e) => {
                      const newSteps = testSteps.map(s =>
                        s.id === step.id ? { ...s, actual: e.target.value } : s
                      );
                      setTestSteps(newSteps);
                    }}
                  />
                </td>
                <td className="px-4 py-3">
                  <select
                    className="w-full px-2 py-1 border rounded"
                    value={step.status}
                    onChange={(e) => {
                      const newSteps = testSteps.map(s =>
                        s.id === step.id ? { ...s, status: e.target.value } : s
                      );
                      setTestSteps(newSteps);
                    }}
                  >
                    <option value="N/A">N/A</option>
                    <option value="pass">Pass</option>
                    <option value="fail">Fail</option>
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-6 flex justify-center">
        <button
          onClick={() => {
            setConfirmedSteps(testSteps.filter(step => step.actual.trim() !== ''));
            startLoading(3.5);
          }}
          className="px-8 py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-lg shadow-lg hover:from-indigo-700 hover:to-purple-700 transition duration-300 flex items-center gap-2"
        >
          <CheckCircle className="w-5 h-5" />
          Confirm Test Cases
        </button>
      </div>
    </div>
  );
  const renderPage4 = () => (
    <div className="max-w-6xl mx-auto p-4 md:p-8">
      <div className="bg-white rounded-lg shadow-xl p-6 mb-8">
        <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-6">
          Detailed Test Analysis
        </h2>
        
        {/* Test Summary Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-blue-50 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold text-blue-800">Total Test Steps</h3>
            <p className="text-3xl font-bold text-blue-600">{testResults.total}</p>
          </div>
          <div className="bg-green-50 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold text-green-800">Passed Tests</h3>
            <p className="text-3xl font-bold text-green-600">{testResults.passed}</p>
          </div>
          <div className="bg-red-50 p-6 rounded-lg shadow">
            <h3 className="text-xl font-semibold text-red-800">Failed Tests</h3>
            <p className="text-3xl font-bold text-red-600">{testResults.failed}</p>
          </div>
        </div>

        <div className="text-lg text-gray-700 mb-8 bg-yellow-50 p-4 rounded-lg">
          <span className="font-semibold">Time Saved: </span>
          By using ValueAddTestR (NV) you've saved {savedTime} seconds compared to creating manual test steps
        </div>

        <div className="h-96">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={[
                {
                  name: 'Test Results',
                  total: testResults.total,
                  passed: testResults.passed,
                  failed: testResults.failed,
                },
              ]}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={(value) => Math.round(value)} label={{ value: 'Number of Test Steps', angle: -90, position: 'insideLeft' }} />
              <Tooltip formatter={(value) => Math.round(value)} />
              <Legend verticalAlign="top" height={36} />
              <Bar dataKey="total" fill="#4338ca" name="Total Test Steps" />
              <Bar dataKey="passed" fill="#059669" name="Passed Tests" />
              <Bar dataKey="failed" fill="#dc2626" name="Failed Tests" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="mt-8 text-center">
          <button
            onClick={() => setCurrentPage(1)}
            className="px-8 py-3 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-lg shadow-lg hover:from-purple-700 hover:to-blue-700 transition duration-300 flex items-center gap-2 mx-auto"
          >
            <Home className="w-5 h-5" />
            Start New Test Suite
          </button>
        </div>
      </div>
    </div>
  );


  // Additional render functions (renderPage2, renderPage3, renderPage4, etc.)
  // ... [Previous render functions remain the same, just without TypeScript types]

  const renderLoadingScreen = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-8 flex flex-col items-center">
        <Timer className="w-16 h-16 text-blue-600 animate-spin" />
        <p className="mt-4 text-lg font-medium">Loading...</p>
      </div>
    </div>
  );

  const renderConfirmedTestSteps = () => (
    <div className="max-w-6xl mx-auto p-4 md:p-8">
      <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-6">
        Confirmed Test Steps
      </h2>
      <div className="bg-white rounded-lg shadow-xl p-6">
        {userStories.map((story, storyIndex) => (
          <div key={storyIndex} className="mb-8">
            <div className="bg-blue-50 p-4 rounded-lg mb-4">
              <h3 className="text-xl font-semibold text-blue-800 mb-2">User Story #{storyIndex + 1}</h3>
              <pre className="whitespace-pre-wrap text-gray-700">{story}</pre>
            </div>
            
            <div className="overflow-x-auto">
              <table className="w-full mb-6">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-4 py-3 text-left">Step Description</th>
                    <th className="px-4 py-3 text-left">Expected Result</th>
                    <th className="px-4 py-3 text-left">Actual Result</th>
                    <th className="px-4 py-3 text-left">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {confirmedSteps
                    .filter(step => step.id.startsWith(`${storyIndex}-`))
                    .map((step) => (
                      <tr key={step.id} className="border-t">
                        <td className="px-4 py-3">{step.description}</td>
                        <td className="px-4 py-3">{step.expected}</td>
                        <td className="px-4 py-3">{step.actual}</td>
                        <td className="px-4 py-3">
                          <span className={`px-2 py-1 rounded-full text-sm font-medium
                            ${step.status === 'pass' ? 'bg-green-100 text-green-800' : ''}
                            ${step.status === 'fail' ? 'bg-red-100 text-red-800' : ''}
                            ${step.status === 'N/A' ? 'bg-gray-100 text-gray-800' : ''}
                          `}>
                            {step.status.toUpperCase()}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
        
        <div className="mt-8 flex justify-center">
          <button
            onClick={() => {
              updateTestResults();
              startLoading(4);
            }}
            className="px-8 py-3 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-lg shadow-lg hover:from-purple-700 hover:to-blue-700 transition duration-300 flex items-center gap-2"
          >
            <AlertCircle className="w-5 h-5" />
            Test Analysis
          </button>
        </div>
      </div>
    </div>
  );
  return (
    <div className="min-h-screen bg-gray-50">
      {isLoading && renderLoadingScreen()}
      <div className="container mx-auto px-4 py-8">
        {currentPage === 1 && renderPage1()}
        {currentPage === 2 && renderPage2()}
        {currentPage === 3 && renderPage3()}
        {currentPage === 3.5 && renderConfirmedTestSteps()}
        {currentPage === 4 && renderPage4()}
      </div>
      <footer className="fixed bottom-0 w-full bg-white border-t p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="text-sm text-gray-600">
            © {new Date().getFullYear()} ValueAddTestR (NV)
          </div>
          <button
            onClick={() => setCurrentPage(1)}
            className="p-2 rounded-full hover:bg-gray-100"
          >
            <Home className="w-6 h-6 text-gray-600" />
          </button>
        </div>
      </footer>
    </div>
  );
};

export default ValueAddTestR;